html {
  background-color: rgb(30, 30, 30);
  margin-left: 30px;
  margin-top: 20px;
}

h1 {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

h1, h2 {
  color: orange;
}

h3, h4, p, label {
  color: white;
}

.header {
  display: inline-flex;
}

.addPost {
  background-color: rgb(50,50,50);
  padding: 20px;
  width: 400px;
  margin: auto;
}

.post {
  background-color: rgb(50,50,50);
  padding: 20px;
  width: 400px;
  position: relative;
  margin-bottom: 20px;
}
